import React, { Fragment, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Modal,
  Spin,
  Tooltip,
  Typography,
  Link,
  message,
  Drawer,
} from "../../common/UIComponents";
import { fetchBillingDetails, printBillingPdf, regenerateInvoiceWithNewLos } from "../../api/Billing";
import { alertMessage, base64ToPdf, checkNegitive } from "../../common/Common";
import moment from "moment";
import { BillingConsolidationApi } from "../../api/BilliingConsolidationApi";
import userStore from "../../stores/UserStore";
import Consolidate from "./Consolidate";
import BaseModal from "../BaseModal";
import { getFMPOrderFromInvoice, handleInvoiceResponse } from "../../helpers/billing";
import Form from "./InvoiceForm";
import { OrgContext } from "../../context/OrgContext";
import EditOrderFormComponent from "../orders/EditOrderForm";
import { fetchOrderDetails } from "../../api/OrdersApi";
import I18n from "../../common/I18n";
import { renderAlertMessage } from "../../helpers/common";


const { Paragraph } = Typography;

const OrderBillingDetails = ({
  record = {},
  keytoShow,
  showActions,
  className,
  buttonType,
  size,
  label,
  isDisabled,
  orders = [],
  displayType = "manage",
  consolidate_order_id = "",
  consolidation_no = "",
  handleSuccess = () => {},
  styleObj = {},
  history = {},
}) => {
  const orgId = userStore.getStateValue("selectedOrg");
  const [isLoading, setIsLoading] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [currentOrder, setCurrentOrder] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const [activeRecordId, setActiveRecordId] = useState(null);


  const editFormDetails = (id) => {
      fetchOrderDetails(id).then((result) => {
        if (result.success) {
          const order =
            result.orders.length > 0 ? _.find(result.orders, { id: id }) : {};
          if (!currentOrder.id) {
            order.id = id;
          }
          setCurrentOrder(order)
          setShowEditForm(true)
        } else {
          // alertMessage(result.errors[0], 'error', 10);
        }
      });
  };

  const getBillingDetails = (id) => {
    setIsLoading(true)
    setActiveRecordId(id)
    fetchBillingDetails(id).then((result) => {
      if (result.success) {
        const currentInvoice = handleInvoiceResponse(result, id);
        setCurrentInvoice(currentInvoice)
        setIsLoading(false)
        setShowModal(true);
      } else {
        setIsLoading(false)
        renderAlertMessage(result.errors)
        setCurrentInvoice(null)
        setActiveRecordId(null)
      }
    });
  };

  const regenerateInvoiceWithLos = (invoiceLos) => {
    setIsLoading(true)
    const data = {
      organization_id: userStore.getStateValue("selectedOrg"),
      // reference_id:
      //   this.state.currentInvoice.order_group_type !== "MANUAL" // === "ORDER"
      //     ? this.state.currentInvoice.customer_order_id
      //     : this.state.currentInvoice.order_number,
      fmp_order_id: getFMPOrderFromInvoice(currentInvoice),
      account_id: currentInvoice.account_details.id,
      orders_info: Object.keys(invoiceLos).map((rec) => ({
        customer_order_id: rec,
        los_code: invoiceLos[rec],
      })),
      // order_type: this.state.currentInvoice.order_group_type === 'MANUAL' ? 'MANUAL' : 'ORDER',
    };
    message.loading({
      key: "generate",
      content: I18n.t("messages.generating"),
    });
    regenerateInvoiceWithNewLos(data)
      .then((result) => {
        if (result.success) {
          message.success({
            key: "generate",
            content: I18n.t("messages.generated"),
          });
          const currentInvoice = handleInvoiceResponse(
            result,
            currentInvoice.customer_order_id
          );
          setCurrentInvoice(currentInvoice)
         
        } else {
          message.error({
            key: "generate",
            content: result.errors.join(","),
          });
        }
      })
      .finally(() => {
        setIsLoading(false)
      });
  };


  const closeWindow = () => {
    setShowModal(false);
    setCurrentInvoice(null);
  };

  const renderWindow = () => {
    // const account = orders?.length > 0 ? {
    //     id: orders[0].account_id,
    //     name: orders[0].account_name,
    //     code: orders[0].account_code,
    // } : {}
    return (
      <BaseModal
        title={`${I18n.t("consolidation.label")}`}
        width="80%"
        style={{
          top: 10,
          height: "90%",
        }}
        // bodyStyle={{
        //   height: "100%",
        //   overflowY: "auto",
        // }}
        bodyStyle={{
          height: "calc(100vh - 100px)",
          overflowY: "scroll",
          padding: 20,
        }}
        visible={showModal}
        onCancel={() => closeWindow()}
      >
        <Spin
              spinning={isLoading}
            >
              <Form
                getInvoices={() => {}}
                currentInvoice={currentInvoice}
                isNew={false}
                onCancel={() => closeWindow()}
                onChange={(element, value) => this.handleOnChange(element, value)}
                getInvoiceDetails={(orderId) => getBillingDetails(orderId)}
                getManualInvoiceDetails={() => {}}
                showHeaders={true}
                handleOrderDetailsViewClick={(order_id) =>
                  {

                  }
                }
                regenerateInvoice={regenerateInvoiceWithLos}
                handleEditOrder={(order_id) => editFormDetails(order_id)}
                showFirstOne={false}
                organizationSettings={organizationSettings}
                showActions ={showActions}
                activeRecordId={activeRecordId}
                handleIsLosChanged={() => {}}
              />
            </Spin>
      </BaseModal>
    );
  };

  const closeEditFormModal = () => {
    setShowEditForm(false)
    setCurrentOrder({})
  };

  const handleOrderSaveSuccess = (
    callbackMessage,
  ) => {
    alertMessage(callbackMessage);
    closeEditFormModal();
  };

  return (
    <Fragment>
        <Row type="flex" style={{ flexFlow: "nowrap" }}>
          <Col>
          <Spin
              spinning={isLoading}>
          <Link
           onClick={() => getBillingDetails(record.customer_order_id)}
          >{keytoShow ? checkNegitive(record[keytoShow]) :record.customer_order_number}</Link>
          </Spin>
          </Col>
          {!keytoShow && <Col>
            <Paragraph
              copyable={{ text: record.customer_order_number }}
            ></Paragraph>
          </Col>
}
        </Row>
     
      {showModal && renderWindow()}
      { showEditForm && (
          <Drawer
            placement="right"
            closable={ false }
            maskClosable={ false }
            onClose={ () => {
              closeEditFormModal()
            }}
            visible={ showEditForm }
            width="90%"
          >
            <EditOrderFormComponent
              callFrom="recoveries"
              customerDetails={ currentOrder }
              customerOrderId={ currentOrder.id }
              preferences={ [] }
              onCancel={ () => closeEditFormModal() }
              handleSuccess={handleOrderSaveSuccess}
              isNew={ false }
              tab="accessorials"
            />
          </Drawer>
        ) }
    </Fragment>
  );
};
export default withRouter(OrderBillingDetails);
