/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import {
  Col,
  Input,
  FormItem,
  Row,
  DatePicker,
  Select,
  Radio,
  Divider,
  Button,
} from "../../common/UIComponents";
import { isEmpty, disabledDate, alertMessage } from "../../common/Common";
import I18n from '../../common/I18n';
import { fetchShortFormAccounts } from "../../api/Account";
import { fetchLocations } from "../../api/LocationsApi";
import AppConfig from "../../config/AppConfig";
import ZonesList from "../../containers/ZonesList";
import WarehouseFilter from "../common/WarehouseFilter";
import { defaultSearchOrdersfilter } from "../dispatches/helpers";
import AccountCodesFilter from "../../containers/Filters/AccountCodesFilter";
import { WarehouseContext } from "../../context/WarehouseContext";
import { OrgContext } from "../../context/OrgContext";
import { removeSpecialCharacters, renderAlertMessage } from "../../helpers/common";
import OrderSortByOptions from "./OrdersSortByOptions";
import { VehicleTypeApi } from "../../api/VehicleType";
import{ clearSearchCriteria } from "../../api/OrdersApi";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import { getFromToDate, getTimeFrameTitle } from "./helpers";
import { fetchActiveUsers, fetchFilteredUser } from "../../api/UsersApi";
import userStore from "../../stores/UserStore";

const { Search } = Input;
const { RangePicker } = DatePicker;
const RadioGroup = Radio.Group;
const dateFormat = "Do MMM YYYY";

class OrderFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.props.filter || {},
      accounts: [],
      users : [],
      warehouses: this.props.warehouses || [],
      accountsLoading: true,
      vehicleTypes: [],
      usersLoading: false,
    };
  }

  getVehicleTypes = () => {
    VehicleTypeApi.fetch().then((result) => {
      if (result.success) {
        this.setState({
          vehicleTypes: result.vehicle_types || [],
        });
      } else {
        renderAlertMessage(result.errors)
        this.setState({ vehicleTypes: [], inProgress: false });
      }
    });
  };

  getFilteredUsers = () => {
    this.setState({ usersLoading: true });
    fetchFilteredUser()
      .then((result) => {
        if (result.success) {
          this.setState({users: result.users || [],usersLoading: false});
        } else {
          renderAlertMessage(result.errors)
          this.setState({ users : [],usersLoading: false });
      }
    });
  };

  componentDidMount() {
    this.getAccounts();
    this.getVehicleTypes();
    this.getFilteredUsers();
    // this.getWarehouses();
  }

  // on change of accounts or warehouses push to parent
  componentDidUpdate(prevProps, prevState) { 
    if (!_.isEqual(prevProps.warehouseFilter.warehouseWithAccounts, this.props.warehouseFilter.warehouseWithAccounts)) {
      this.getAccounts(this.props.warehouseFilter.selectedWarehouses);
    } else if (
      !_.isEqual(prevProps.warehouseFilter.selectedWarehouses, this.props.warehouseFilter.selectedWarehouses) ||
      (!_.isEqual(prevProps.warehouseFilter.warehouses, this.props.warehouseFilter.warehouses) &&
        !this.props.warehouseFilter.isFetchingWarehouses)
    ) {
      this.getAccounts(this.props.warehouseFilter.selectedWarehouses);
    }
  }

  getAccounts = (whId) => {
    const accountsData = this.props.warehouseFilter.findMatchingAccounts(whId);
    this.setState({
      accounts: accountsData,
    }), ()  => {
      this.setState({
        filter :{
          ...this.state.filter,
          account_codes: this.state.accounts?.map((acc) => acc.code)
        }
      })
    }
  };

  handleFilterPlaceHolderChange = (element, value) => {
    if (element === "account_codes") {
      // const selectedAccount = _.compact(value);
      if (value.includes("all")) {
        value = this.state.accounts?.map((acc) => acc.code);
      }
      // const filteredAccount = selectedAccount.length
      //   ? selectedAccount[0] === ""
      //     ? selectedAccount.slice(1)
      //     : selectedAccount[selectedAccount.length - 1] === ""
      //     ? []
      //     : selectedAccount
      //   : [];
      // value =
      //   filteredAccount.includes("") ||
      //   filteredAccount.length === this.state.accounts.length
      //     ? "all"
      //     : filteredAccount;
    }
    const filter = Object.assign({}, this.state.filter, {
      [element]: value,
    });
    if (element === "search_key_type" && value === "") {
      filter.search_key_value = "";
    }
    if (
      element === I18n.t("order.filters.schedule_orders.type")
    ) {
      if (value === "SCHEDULED") {
        const { fromDate, toDate } = getFromToDate('Current Week');
        filter.fromDate = fromDate;
        filter.toDate = toDate;
        filter.schedule_day_filter = "Current Week";
      }
      else if (value === "UNSCHEDULED") {
        filter.fromDate = null;
        filter.toDate = null;
        filter.schedule_day_filter = "";
      }
    }
    if (element === "sortBy") {
      const sortByValue =
        value && _.isObject(value)
          ? value
          : { sortKey: "none", sortKeyType: "descend" };
      filter.sortBy = sortByValue.sortKey;
      filter.sortByType = sortByValue.sortKeyType;
    }
    if (element === "order_type" && value === "") {
      filter.order_type = "";
    }
    if(element === "vehicle_type" && value === "") {
      filter.vehicle_type = "";
    }
    if(element === "created_by_ids" && value === "") {
      filter.created_by_ids = "";
    }
    this.props.onChange(filter);
  };

  orderNumberSearch = (value) => {
    const filter = Object.assign({}, this.state.filter, {
      search_order_token: value,
    });
    this.props.onChange(filter, () => {
      this.props.onOrderNoSearch();
    });
  };

  clearForm = () => {
    const { pathname } = this.props.location;
    const { fromDate, toDate } = getFromToDate('Current Week')
    let clearFilter = this.props.getDefaultGlobalFilter(fromDate , toDate);
    if (pathname === "/orders") {
    } else if (pathname === "/routes") {
      clearFilter = this.props.defaultFilter;
    } else if (pathname === "/pending_orders") {
      clearFilter = {
        ...clearFilter,
        currentStatus: "PENDING",
        fromDate: null,
        toDate: null,
        [I18n.t('order.filters.schedule_orders.type')]: I18n.t(
          'order.filters.schedule_orders.both_key',
        ),
      };
    }
    // this.setState({scheduleDayFilter: "Current Week"});
    this.props.onChange(clearFilter, () => {
      this.props.onSearch();
    });
  };

  clearSearchHistory = () => {
    clearSearchCriteria().then((result) => {
      let clearFilter;
      if (result.success) {
        alertMessage(result?.message?.length? result.message[0] : "Search history deleted successfully", "success", 10);
        // this.setState({scheduleDayFilter: "Current Week"});
        clearFilter = {
          fromDate: moment(),
          toDate: moment().add(6, "day"),
          filter: this.props.isPending
            ? "PENDING"
            : this.state.filter?.filter
            ? this.state.filter.filter
            : I18n.t("order.default_status"),
          search_order_token: "",
          sortBy: this.state.filter?.sortBy ? this.state.filter.sortBy : "none",
          sortByType: this.state.filter?.sortByType
            ? this.state.filter.sortByType
            : "descend",
          account_codes: [], //this.props.isShipper && this.state.filter.account_codes ? this.state.filter.account_codes : [],
          warehouse_id: "",
          search_type : this.state.filter?.search_type ? this.state.filter.search_type : "EQUALS",
          search_key_type: this.state.filter.search_type !== "EQUALS" && this.state.filter?.search_key_type ? this.state.filter.search_key_type : "",
          search_key_value: "",
          [I18n.t("order.filters.schedule_orders.type")]: I18n.t(
            "order.filters.schedule_orders.scheduled_key"
          ),
          zone_ids: [],
          order_type: "",
          vehicle_type: "",
        };
      } else {
        alertMessage("No Search history found!", "error", 10);
      }
      this.props.onChange(clearFilter);
      this.props.onSearch();
    })
  }

  handleRangeChange = (dates, dateStrings) => {
    let finalFilter = {};
    if (!(dateStrings[0] !== "" && dateStrings[1] !== "")) {
      finalFilter = Object.assign({}, this.state.filter, {
        fromDate: moment().startOf("month"),
        toDate: moment(),
      });
    } else {
      finalFilter = Object.assign({}, this.state.filter, {
        fromDate: dates[0],
        toDate: dates[1],
      });
    }
    const dateTimeFrame = getTimeFrameTitle(dates[0], dates[1])
    this.props.onChange({
      ...finalFilter,
      schedule_day_filter : dateTimeFrame
    });
  };

  handleDateFilter = (type) => {
    let filterType = type["item"];
    const { fromDate, toDate } = getFromToDate(filterType);
    this.props.onChange({
      ...this.props.filter,
      fromDate,
      toDate,
      schedule_day_filter : filterType,
      schedule_orders_type: "SCHEDULED",
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      filter: nextProps.filter || {},
    });
  }

  render() {
    const elementSize = this.props.size ? this.props.size : "default";
    const { pathname } = this.props.location;
    const { orderTypeKeysForFilter} = this.props;
    return (
      <Fragment>
        {this.props.showModel !== "inline" ? (
          <div>
            {this.props.showOrderNoSearch !== false && (
              <Fragment>
                <FormItem label="Search By Order">
                  <Search
                    placeholder="Search with order number"
                    enterButton="Search"
                    size={elementSize}
                    value={this.state.filter.search_order_token}
                    onChange={(e) =>
                      this.handleFilterPlaceHolderChange(
                        "search_order_token",
                        e.target.value
                      )
                    }
                    onSearch={(value) => this.orderNumberSearch(value)}
                  />
                </FormItem>

                <Divider>( Or )</Divider>
              </Fragment>
            )}
            {this.props.showOrderNoSearch !== false && (
              <h4 className="textGrey textBold">Filter By</h4>
            )}
            {!this.props.isShipper && (
              <FormItem label={I18n.t("general.account")}>
                <AccountCodesFilter
                  SelectedAccountCodes={this.state.filter?.account_codes}
                  onValueChange={this.handleFilterPlaceHolderChange}
                  accounts={this.state?.accounts}
                  size="default"
                  element="account_codes"
                  valueField="code"
                />
              </FormItem>
            )}
            <Row gutter={8}>
              <Col span={12}>
            {this.props.showZones !== false && (
              <FormItem label={I18n.t("general.zone")}>
                <ZonesList
                  handleFilterPlaceHolderChange={
                    this.handleFilterPlaceHolderChange
                  }
                  zone_ids={this.state.filter.zone_ids}
                  size={elementSize}
                  onZonesChange={this.props.onZonesChange}
                />
              </FormItem>
            )}
            </Col>
            <Col span={12}>
              <FormItem label={I18n.t('general.created_by')}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  allowClear={true}
                  placeholder = "---Select---"
                  maxTagCount={1}
                  mode="multiple"
                  onChange={(e) =>
                    this.handleFilterPlaceHolderChange("created_by_ids", e)
                  }
                  value={this.state.filter.created_by_ids}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  loading={this.state.usersLoading}
                >
                  {this.state.users.map((code) => (
                    <Select.Option key={code.user_id} value={(code.user_id)}>
                      {code.user_name}
                    </Select.Option>
                  ))}  
                </Select>
              </FormItem>
              </Col>
            
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <FormItem label={I18n.t("order.type")}>
                  <Select
                    value={this.state.filter.order_type || ""}
                    onChange={(e) =>
                      this.handleFilterPlaceHolderChange("order_type", e)
                    }
                    className="width100Per"
                    size={elementSize}
                  >
                    <Select.Option value="">-- All --</Select.Option>
                    {Object.keys(orderTypeKeysForFilter).map(
                      (opt) => (
                        <Select.Option value={opt}>
                          {orderTypeKeysForFilter[opt]}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
              <FormItem label={I18n.t("vehicleTypes.title")}>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  allowClear={true}
                  onChange={(e) =>
                    this.handleFilterPlaceHolderChange("vehicle_type", e)
                  }
                  value={this.state.filter.vehicle_type || ""}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option key={"vh_code"} value={""}>
                    ---Select---
                  </Select.Option>
                  {this.state.vehicleTypes.map((code) => (
                    <Select.Option key={code.name} value={(code.code).replace(/\s/g," ")}>
                      {code.code}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
              </Col>
              
            </Row>
            {/* {this.props.showWarehouses === true && (
              <FormItem label={I18n.t("location.location")}>
                <WarehouseFilter isDisabled={true} 
                  style={{ width: "100%" }}
                />
              </FormItem>
            )} */}
            {this.props.showDateFilter !== false && (
              <FormItem label={`${I18n.t("general.scheduled")} Date`}>
                <div style={{ maxWidth: 520 }}>
                  <Row gutter={ [ 8, 8 ] } type="flex">
                    {
                      [
                        "Last Month",
                        "Last Week",
                        "Yesterday",
                        'Today',
                        'Tomorrow',
                        'Today & Tomorrow',
                        'Current Week',
                        'Next 3 Days',
                        'Next 7 Days',
                        'Current Month'
                      ].map((item) => (
                        <Col>
                          <Button
                            size="small"
                            style={ {
                              borderColor: '#42A8FB',
                              fontSize: 12,
                              backgroundColor: item === this.state.filter.schedule_day_filter ? '#42A8FB' : 'transparent',
                              color: item === this.state.filter.schedule_day_filter ? "#FFFFFF" : '#42A8FB'
                            } }
                            onClick={ () => this.handleDateFilter({ item }) }
                          >
                            { item }
                          </Button>
                        </Col>
                      ))
                    }
                  </Row>
                </div>
                <RangePicker
                  // disabledDate={disabledDate}
                  defaultValue={[moment().startOf("month"), moment()]}
                  value={[this.state.filter.fromDate, this.state.filter.toDate]}
                  format={dateFormat}
                  style={{ width: "100%", paddingTop: 3 }}
                  onChange={(dates, dateStrings) =>
                    this.handleRangeChange(dates, dateStrings)
                  }
                  allowClear={false}
                  disabled={
                    this.state.filter[
                      I18n.t("order.filters.schedule_orders.type")] ===
                      I18n.t("order.filters.schedule_orders.unscheduled_key")

                  }
                  size={elementSize}
                  // renderExtraFooter={() => <div>
                  //   <Row gutter={6} type="flex">
                  //     {
                  //       ['Today', 'Tomorrow', 'Today & Tomorrow', 'Current Week', 'Next 3 Days', 'Next 7 Days', 'Current Month'].map((item) => (
                  //         <Col> 
                  //           <Button size="small" style={{borderColor: '#42A8FB', backgroundColor: item === this.state.scheduleDayFilter ? '#42A8FB' : 'transparent', color: item === this.state.scheduleDayFilter ? "#FFFFFF" : '#42A8FB' }} onClick={() => this.handleDateFilter({item})}>{item}</Button>                        
                  //         </Col>
                  //       ))
                  //     }
                  //   </Row>
                  //   </div>}
                />
              </FormItem>
            )}
            {/* <Row gutter={16}>
              <Col xs={8}>
                <FormItem label={I18n.t("search.by")}>
                  <Select
                    value={this.state.filter.search_key_type || ""}
                    onChange={(e) =>
                      this.handleFilterPlaceHolderChange("search_key_type", e)
                    }
                    className="width100Per"
                    size={elementSize}
                  >
                    <Select.Option value="">-- Select --</Select.Option>
                    <Select.Option value="hawb">
                      {I18n.t("general.hawb")}
                    </Select.Option>
                    <Select.Option value="mawb">
                      {I18n.t("general.mawb")}
                    </Select.Option>
                    <Select.Option value="reference_1">
                      {I18n.t("general.reference_1")}
                    </Select.Option>
                    <Select.Option value="reference_2">
                      {I18n.t("general.reference_2")}
                    </Select.Option>
                    <Select.Option value="name_search_key">
                      {I18n.t("order.filters.name_search_key")}
                    </Select.Option>
                    <Select.Option value="phone_search_key">
                      {I18n.t("order.filters.phone_search_key")}
                    </Select.Option>
                    <Select.Option value="routing">
                      {I18n.t("order.routing")}
                    </Select.Option>
                  </Select>
                </FormItem>
              </Col>
              <Col xs={16} className="search_input" >
                <FormItem label={I18n.t("search.value")}>
                  <Search
                    placeholder={I18n.t("search.value")}
                    size={elementSize}
                    value={this.state.filter.search_key_value}
                    onChange={(e) => {
                      const filteredString = removeSpecialCharacters(
                        e.target.value
                      );
                      this.handleFilterPlaceHolderChange(
                        "search_key_value",
                        filteredString
                      );
                    }}
                    onSearch={(value) => this.props.onSearch(value)}
                    disabled={isEmpty(this.state.filter.search_key_type)}
                    prefix={
                      <Row
                          type="flex"
                          align="middle"
                          className="invoiceSearchPrefixes"
                          gutter={4}
                        >
                          <Col>
                            <Select
                              size="small"
                              onChange={(e) => {
                                // this.props.setSearchType(e);
                                this.props.onChange({
                                  ...this.state.filter,
                                  search_type: e,
                                })
                              }}
                              value={this.state.filter.search_type}
                              style={{
                                //textAlign: "center",
                                fontSize: "14px",
                                lineHeight: "1",
                              }}
                              // defaultValue={this.props.searchType === "CONTAINS" ? "CONTAINS" : "EQUALS"}
                              dropdownMatchSelectWidth={false}
                            >
                              <Select.Option key="CONTAINS" value="CONTAINS">CONTAINS</Select.Option>
                              <Select.Option key="EQUALS" value="EQUALS">EQUALS</Select.Option>
                            </Select>
                          </Col>
                        </Row>
                    }
                  />
                </FormItem>
              </Col>
            </Row> */}
            {/* {pathname === "/routes" && (
              <FormItem label={I18n.t("general.sort_by")}>
                <OrderSortByOptions
                  value={this.state.filter.sortBy || "none"}
                  onChange={this.handleFilterPlaceHolderChange}
                  className="width100Per"
                  size={elementSize}
                  screenFrom={"dispatch"}
                />
              </FormItem>
            )} */}

            {/* <RadioGroup
                onChange={(e) =>
                  this.handleFilterPlaceHolderChange("sortBy", e.target.value)
                }
                value={this.state.filter.sortBy}
                size={elementSize}
              >
                <Radio value="none">{I18n.t("order.filters.sortBy.none")}</Radio> 
                <Radio value="city">{I18n.t("order.filters.sortBy.city")}</Radio>
                <Radio value="account_code">{I18n.t("order.filters.sortBy.account_code")}</Radio>
              </RadioGroup> 
            </FormItem> */}
              {this.props.showScheduleOption && (
              <Row gutter={8}>
              
                <Col span={12}>
                  <FormItem label={I18n.t("order.filters.schedule_orders.label")}>
                    <Select
                      onChange={(value) =>
                        this.handleFilterPlaceHolderChange(
                          I18n.t("order.filters.schedule_orders.type"),
                          value
                        )
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      style={{ width: "100%" }}
                      value={
                        this.state.filter[I18n.t("order.filters.schedule_orders.type")]
                      }
                    >
                      <Select.Option
                        value={I18n.t("order.filters.schedule_orders.scheduled_key")}
                      >
                        {I18n.t("order.filters.schedule_orders.SCHEDULED_label")}
                      </Select.Option>
                      <Select.Option
                        value={I18n.t("order.filters.schedule_orders.unscheduled_key")}
                      >
                        {I18n.t("order.filters.schedule_orders.UNSCHEDULED_label")}
                      </Select.Option>
                      <Select.Option value={I18n.t("order.filters.schedule_orders.both_key")}>
                        {I18n.t("general.both")}
                      </Select.Option>
                    </Select>
                  </FormItem>
                </Col>
                  {this.props.undeliveredOrdersSelection !== false && (
                <Col span={12}>
                  <FormItem label={"Order Creation Source"}>
                    <Select
                      onChange={(value) =>
                        this.handleFilterPlaceHolderChange("insertion_type",value)
                      }
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: "100%" }}
                      showSearch
                      mode="multiple"
                      maxTagCount={2}
                      value={this.state.filter.insertion_type}
                      
                    >
                      {AppConfig.insertionTypes.map((type) => (
                        <Select.Option key={type.value} value={type.value}>
                          {type.key}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                )}
              </Row>
           )}

            <Row type="flex" align="middle" gutter={ 8 } justify="center">
              <Col>
                <Button
                  icon="reload"
                  type="danger"
                  onClick={ this.clearForm }
                  size={ elementSize }
                >
                  Reset
                </Button>
              </Col>
              <Col>
                <Button
                  icon="search"
                  onClick={ () => this.props.onSearch() }
                  type="primary"
                  size={ elementSize }
                >
                  Search
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            {this.props.showOrderNoSearch !== false && (
              <Fragment>
                <FormItem label="Search By Order">
                  <Search
                    placeholder="Search with order number"
                    enterButton="Search"
                    size={elementSize}
                    value={this.state.filter.search_order_token}
                    onChange={(e) =>
                      this.handleFilterPlaceHolderChange(
                        "search_order_token",
                        e.target.value
                      )
                    }
                    onSearch={(value) => this.orderNumberSearch(value)}
                  />
                </FormItem>

                <Divider>( Or )</Divider>
              </Fragment>
            )}
            {this.props.showDateFilter !== false && (
              <FormItem label="Select Date">
                <RangePicker
                  disabledDate={disabledDate}
                  defaultValue={[moment().startOf("month"), moment()]}
                  value={[this.state.filter.fromDate, this.state.filter.toDate]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Week": [moment().startOf("week"), moment()],
                    "This Month": [moment().startOf("month"), moment()], // moment().endOf('month')
                    // 'This Year': [moment().startOf('year'), moment()],
                    // 'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                    // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                  }}
                  size="small"
                  format={dateFormat}
                  onChange={(dates, dateStrings) =>
                    this.handleRangeChange(dates, dateStrings)
                  }
                />
              </FormItem>
            )}
            {/* <FormItem label='Select Status' >
    <RadioGroup
      onChange={e => this.handleFilterPlaceHolderChange('filter', e.target.value)}
      value={this.state.filter.filter}
    >
     {
       Object.keys(this.state.status).map((key,index)=>{
       return <Fragment key={index}><Radio value={this.state.status[key]} >{key}</Radio><br/></Fragment>
       })
     }
    </RadioGroup>
  </FormItem> */}
            {I18n.t("general.sort_by")} :&nbsp;
            <Select
              onChange={(e) => this.handleFilterPlaceHolderChange("sortBy", e)}
              value={this.state.filter.sortBy}
              size={elementSize}
              dropdownMatchSelectWidth={false}
            >
              <Select.Option value="none">None</Select.Option>
              <Select.Option value="state">Location</Select.Option>
              <Select.Option value="zip_code">Zip Code</Select.Option>
              <Select.Option value="account">Account</Select.Option>
            </Select>
            &nbsp;&nbsp;
            {I18n.t("general.account")} :&nbsp;
            <Select
              value={this.state.filter.accountCode || ""}
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) =>
                this.handleFilterPlaceHolderChange("accountCode", e)
              }
              size={elementSize}
              dropdownMatchSelectWidth={false}
            >
              <Select.Option key="all" value="">
                -- All --
              </Select.Option>
              {this.state.accounts?.map((account) => (
                <Select.Option key={account.code} value={account.code}>
                  {account.name}
                </Select.Option>
              ))}
            </Select>
            &nbsp;&nbsp;
            <Button size="small" onClick={this.props.onSearch} type="primary">
              Search
            </Button>
          </div>
        )}
      </Fragment>
    );
  }
}

OrderFilter.propTypes = {
  filter: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onOrderNoSearch: PropTypes.func,
  warehouses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onZonesChange: PropTypes.func,
  onAccountsChange: PropTypes.func,
  onWarehousesChange: PropTypes.func,
  showSaveNSearch: PropTypes.bool,
  showClearFilter: PropTypes.bool
};
OrderFilter.defaultProps = {
  filter: {},
  onOrderNoSearch: null,
  onZonesChange: () => {},
  onAccountsChange: () => {},
  onWarehousesChange: () => {},
  showSaveNSearch: false,
  showClearFilter: false,
};
// export default withRouter(OrderFilter);

export const OrderFilterComponent = withRouter((props) => {
  const warehouseFilter = useContext(WarehouseContext);
  const { orgSettings: organizationSettings } = useContext(OrgContext);
  const { getDefaultGlobalFilter , orderConfig: { orderTypeKeysForFilter = {} } = {} } = useContext(OrderConfigContext);
  return (
    <OrderFilter
      warehouseFilter={warehouseFilter}
      organizationSettings={organizationSettings}
      orderTypeKeysForFilter={orderTypeKeysForFilter}
      getDefaultGlobalFilter={getDefaultGlobalFilter}
      {...props}
    />
  );
});

export default OrderFilterComponent;
