import React, { useEffect, useState, Fragment, useContext } from "react";
import {
  Search,
  Row,
  Col,
  Modal,
  FormItem,
  DatePicker,
  Icon,
  Drawer,
  Tooltip,
  Spin,
  Link,
  Radio,
} from "../../common/UIComponents";
import PropTypes from "prop-types";
import { OrgContext } from "../../context/OrgContext";
import moment from "moment";
import MaterialTimePicker from "../../components/orders/MaterialTimePicker";
import {
  getOperationalTimings,
  renderAlertMessage,
} from "../../helpers/common";
import { WarehouseContext } from "../../context/WarehouseContext";
import { UserContext } from "../../context/UserContext";
import {
  deleteRoutes,
  newDispatchRoute,
  updateRouteTime,
} from "../../api/Routes";
import { alertMessage } from "../../common/Common";
import RouteLabel from "../../config/RouteLabel";
import { showRouteValidationError } from "../../helpers/capacityValidation";
import { getLocIdFormLoc } from "../../helpers/orders";
import { newDispatchMultiStopRoute } from "../../api/OrdersApi";

const RouteTimeWindow = (props) => {
  const {
    selectedOrders,
    driverDetails,
    showIcon,
    children,
    currentDateTime,
    currentRouteDetails,
    refreshDetails,
    orderRefreshCall,
    requireValidation,
    timeZoneId,
    renderNotification,
    timeToConsider,
    navigateToRoute,
    renderErrors
  } = props;
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [optimizationType, setOptimizationType] = useState('auto');
  const isMultiStopOrder = selectedOrders.some(order => order.type_of_order === "MS"); 
  const { orgSettings: organizationSettings, currentOrg } =
    useContext(OrgContext);
  const warehouseData = useContext(WarehouseContext);
  const selectedWh = warehouseData.getWHDetails()

  const isMilitaryTime = organizationSettings.is_military_time === "true";
  useEffect(() => {
    if (currentDateTime) {
      const dateTime = timeZoneId ? moment.tz(currentDateTime, timeZoneId): moment(currentDateTime);
      setSelectedDate(dateTime);
      // setSelectedTime(dateTime);
    } else {
      setSelectedDate(moment());
      // setSelectedTime(moment());
    }
  }, [currentDateTime]);

  useEffect(() => {
    if (timeToConsider) {
      const dateTime = timeZoneId ? moment.tz(timeToConsider, timeZoneId): moment(timeToConsider);
      setSelectedTime(dateTime);
    } else {
      setSelectedTime(moment());
    }
  }, [timeToConsider]);

  const closeModal = () => {
    setIsWindowOpen(false);
  };

  const handleOnChange = (key, value) => {
    if (key === "selectedDate") {
      setSelectedDate(value);
    } else if (key === "selectedTime") {
      setSelectedTime(value);
    }
  };

  const handleUpdateAllocation = () => {
    let { start_time = "", end_time = "" } = getOperationalTimings(
      organizationSettings,
      selectedWh
    );

    const endTime = currentRouteDetails?.route_end_time
      ? moment(currentRouteDetails.route_end_time).format("HH:mm")
      : end_time;

    const route_id = !_.isEmpty(currentRouteDetails)
      ? currentRouteDetails.route_id
      : "";

    const startDatetime = `${moment(selectedDate).format(
      "YYYY-MM-DD"
    )} ${moment(selectedTime).format("HH:mm")}`;

    const endDateTime = `${moment(currentRouteDetails?.route_end_time).format(
      "YYYY-MM-DD"
    )} ${endTime}`;

    const payload = {
      nav_route_id: route_id,
      start_date_time: startDatetime,
      end_date_time: endDateTime,
      action_from: "ORDER",
      action_type: RouteLabel.actionLabels.ROUTE_TIME_MANAGE,
     // optimisation_type: optimizationType
    };
    setAssignLoading(true);

    updateRouteTime(payload).then((result) => {
      if (result.success) {
        renderNotification(I18n.t("messages.saved"), 'success');
        refreshDetails(driverDetails.driver_id);
        setIsWindowOpen(false);
        setAssignLoading(false);
      } else {
        renderErrors(result.errors)
        //renderNotification(result.errors ? result.errors.join(',') : 'Unable to process the request', 'error');
        setAssignLoading(false);
      }
    });
  };

  const handleAllocation = (requireCapacityValidation) => {
    const organization_id = currentOrg.id;

    let { start_time = "", end_time = "" } = getOperationalTimings(
      organizationSettings,
      selectedWh
    );

    const startDeliveryTime = moment(
      `${moment(selectedDate).format("YYYY-MM-DD")} ${moment(
        selectedTime
      ).format("HH:mm")}`,
      "YYYY-MM-DD HH:mm"
    ).format();
    const endDeliveryTime = moment(
      `${moment(selectedDate).format("YYYY-MM-DD")} ${end_time}`,
      "YYYY-MM-DD HH:mm"
    ).format();
    // const loc_ids = _.compact(
    //   _.flatten(
    //     selectedOrders.map((order) => [
    //       getLocIdFormLoc(order.origin, "location_id"),
    //       getLocIdFormLoc(order.destination, "location_id"),
    //     ])
    //   )
    // );
    const customer_order_ids =  selectedOrders.map((order) => order.id);
    const loc_ids = _.uniq(selectedOrders.map((order) => order.location_id));
    setAssignLoading(true);
    const payload = {
      organization_id,
      warehouse_id: selectedWh?.id || '',
      driver_id: driverDetails.driver_id,
      delivery_end_date: endDeliveryTime,
      delivery_start_date: startDeliveryTime,
      customer_order_ids,
      action_type: RouteLabel.actionLabels.ROUTE_CREATED,
      action_from: "ORDER",
      location_ids: loc_ids,
      skip_capacity_validation: !requireCapacityValidation,
      skip_route_time_validation: false,
      optimization_type: optimizationType

    };

    newDispatchRoute(payload)
      .then((result) => {
        if (result.success) {
          if(result.response.capacity_overloaded){
            showRouteValidationError(
              result.response.capacity_info,
              driverDetails.employee_code,
              () => {handleAllocation(false)},
              setAssignLoading,
            )
          } else {
            const routeId = _.get(result.nav_route,"id", "")
            const routeStatus = _.get(result.nav_route,"r_status", "")
            renderNotification(<Fragment>
              {`${customer_order_ids.length} Order(s) have been assigned to `}<Link onClick={() => navigateToRoute(routeId, routeStatus, selectedDate.format("YYYY-MM-DD"))}>{driverDetails.employee_code}</Link></Fragment> , 'success')

            // renderNotification(
            //   `Orders have been assigned to ${driverDetails.employee_code}`,
            //   "success",
            // );
           
            setIsWindowOpen(false);
            refreshDetails(driverDetails.driver_id, routeId);
            setAssignLoading(false);
          }
        } else {
          renderErrors(result.errors)
          renderNotification(result.errors ? result.errors.join(',') : 'Unable to process the request', 'error');
          setAssignLoading(false);
        }
      })
      .finally(() => {
        
      });
  };

  const handleMultiStopAllocation = (requireCapacityValidation) => {
    const organization_id = currentOrg.id;

    let { start_time = "", end_time = "" } = getOperationalTimings(
      organizationSettings,
      selectedWh
    );

    const startDeliveryTime = moment(
      `${moment(selectedDate).format("YYYY-MM-DD")} ${moment(
        selectedTime
      ).format("HH:mm")}`,
      "YYYY-MM-DD HH:mm"
    ).format();
    const endDeliveryTime = moment(
      `${moment(selectedDate).format("YYYY-MM-DD")} ${end_time}`,
      "YYYY-MM-DD HH:mm"
    ).format();
    const customer_order_ids =  selectedOrders.map((order) => order.id);
    const loc_ids = _.uniq(selectedOrders.map((order) => order.location_id));
    setAssignLoading(true);
    const payload = {
      organization_id,
      warehouse_id: selectedWh?.id || '',
      driver_id: driverDetails.driver_id,
      end_time: endDeliveryTime,
      start_time: startDeliveryTime,
      order_id : customer_order_ids,
      action_type: RouteLabel.actionLabels.ROUTE_CREATED,
      action_from: "ORDER",
      location_ids: loc_ids,
      skip_capacity_validation: !requireCapacityValidation,
      skip_route_time_validation: false,
    };

    newDispatchMultiStopRoute(payload)
      .then((result) => {
        if (result.success) {
          if(result.response.capacity_overloaded){
            showRouteValidationError(
              result.response.capacity_info,
              driverDetails.employee_code,
              () => {handleMultiStopAllocation(false)},
              setAssignLoading,
            )
          } else {
            const routeId = _.get(result.nav_route,"id", "")
            const routeStatus = _.get(result.nav_route,"r_status", "")
            renderNotification(<Fragment>
              {`${customer_order_ids.length} Order(s) have been assigned to `}<Link onClick={() => navigateToRoute(routeId, routeStatus, selectedDate.format("YYYY-MM-DD"))}>{driverDetails.employee_code}</Link></Fragment> , 'success')
            setIsWindowOpen(false);
            refreshDetails(driverDetails.driver_id, routeId);
            setAssignLoading(false);
          }
        } else {
          renderErrors(result.errors)
          renderNotification(result.errors ? result.errors.join(',') : 'Unable to process the request', 'error');
          setAssignLoading(false);
        }
      })
      .finally(() => {
        
      });
  };

  const handleRouteDeleteClick = (routeId) => {
    setAssignLoading(true);
    deleteRoutes(routeId).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.preplan_route_deleted"));
        refreshDetails(driverDetails.driver_id);
        setIsWindowOpen(false);
        setAssignLoading(false);
      } else {
        renderAlertMessage(result.errors);
        setAssignLoading(false);
      }
    });
  };

  const renderNewRouteModal = () => {
    return (
      <Modal
        title={null}
        visible={true}
        footer={null}
        closable={false}
        maskClosable={false}
        className="route-start-modal"
      >
        <Spin spinning={assignLoading} tip="Loading...">
          <Fragment>
            <Row gutter={[16, 16]} className="assign-route">
              <Col xs={24}>
                <div className="modal-header">Set Route Start Time</div>
              </Col>
              <Col xs={24}>
                <div className="description">
                  Set the route start time the driver will begin fulfilling the
                  orders.
                </div>
              </Col>
              <Col xs={24}>
                <div className="lable" style={{ marginBottom: 3 }}>
                  Route Start Date
                </div>
                <div>
                  <DatePicker
                    style={{ width: "100%" }}
                    format="DD MMM, YYYY"
                    value={selectedDate}
                    onChange={(date) => handleOnChange("selectedDate", date)}
                    allowClear={false}
                    // disabledDate={(current) =>
                    //   current && current < moment().startOf("day")
                    // }
                    disabledDate={
                      current => current && timeZoneId && moment(moment(current).format('YYYY-MM-DD')).isBefore(moment().tz(timeZoneId).format('YYYY-MM-DD'))
                    }
                  />
                </div>
              </Col>
              <Col xs={24}>
                <div className="lable" style={{ marginBottom: 8 }}>
                  Route Start Time
                </div>
                <div>
                  <MaterialTimePicker
                    style={{ width: "100%" }}
                    value={selectedTime ? moment(selectedTime, "HH:mm") : null}
                    onChange={(time) => handleOnChange("selectedTime", time)}
                    computeDisabledHours={() => []}
                    isMilitaryTime={isMilitaryTime}
                    computeDisabledMinutes={(pickedTime) => {
                      return [];
                    }}
                    size={"small"}
                  />
                </div>
              </Col>
              {!isMultiStopOrder && <Col xs={24}>
              <div className="lable">
                  Optimization Type
              </div>
              <div>
                <Radio.Group value={optimizationType} onChange={e => setOptimizationType(e.target.value)}>
                   <Radio value="manual">Use Selected Sequence</Radio>
                   <Radio value="auto">Optimize Route</Radio>
                </Radio.Group>
              </div>
            </Col>
            }
            </Row>
            
            <Row
              gutter={[16, 16]}
              type="flex"
              style={{ marginTop: 20 }}
              className="assign-route-footer"
            >
              <Col xs={12} className="alignLeft">
                {currentRouteDetails?.route_status === "ASSIGNED" && (
                  <Tooltip title="Discard Route">
                    <Icon
                      type="delete"
                      className="delete-icon"
                      style={{ marginLeft: 15 }}
                      onClick={() =>
                        handleRouteDeleteClick(currentRouteDetails.route_id)
                      }
                    />
                  </Tooltip>
                )}
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={12} className="hoverable-col" onClick={closeModal}>
                    Cancel
                  </Col>
                  <Col
                    xs={12}
                    className="hoverable-col"
                    onClick={() =>
                      currentDateTime
                        ? handleUpdateAllocation() 
                        : isMultiStopOrder ? handleMultiStopAllocation(requireValidation): handleAllocation(requireValidation)
                    }
                  >
                    Set Time
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fragment>
        </Spin>
      </Modal>
    );
  };
  return (
    <Fragment>
      {showIcon ? (
        <Tooltip title="New Route">
          <Icon
            type="plus-circle"
            className="routeButton"
            onClick={() => setIsWindowOpen(true)}
          />
        </Tooltip>
      ) : (
        <div onClick={() => setIsWindowOpen(true)}>{children}</div>
      )}
      {isWindowOpen && renderNewRouteModal()}
    </Fragment>
  );
};

RouteTimeWindow.propTypes = {
  showIcon: PropTypes.bool,
  currentDateTime: PropTypes.instanceOf(moment),
  currentRouteDetails: PropTypes.object,
  refreshDetails: PropTypes.func,
  orderRefreshCall: PropTypes.func,
  timeZoneId: PropTypes.string,
};

RouteTimeWindow.defaultProps = {
  showIcon: true,
  currentDateTime: null,
  currentRouteDetails: {},
  refreshDetails: () => {},
  orderRefreshCall: () => {},
  timeZoneId: '',
};
export default RouteTimeWindow;