import React from "react";
import PropTypes from "prop-types";
import { TreeSelect } from "antd";
import Text from "antd/lib/typography/Text";
import { isEmpty } from "../../common/Common";
const { TreeNode, SHOW_PARENT, SHOW_CHILD } = TreeSelect;

function TreeSelectComponent(props) {
  const {
    records = [],
    titleField,
    valueField = "id",
    selectedValues = [],
    onValueChange,
    size = "small",
    element = "",
    styleObject = {},
    showLabel = false,
    label = "",
    dropdownMatchSelectWidth=false,
    maxTagCount = 2,
    showCheckedStrategy = SHOW_CHILD,
    useStyleFromParentOnly = false,
    titleFieldCode = "",
  } = props;
  // const handleValueChange = (value) => {
  //   onValueChange(element, value);
  // };
  return (
    <div className="TreeSelectComponentContainer">
      {showLabel && <Text className="marginRight5 textBold">{label}</Text>}
      <TreeSelect
        value={selectedValues}
        style={useStyleFromParentOnly ? {  ...styleObject } : { width: "100%",  ...styleObject }}
        showSearch
        size={size ? size : "small"}
        className="minWidth"
        dropdownStyle={{
          maxHeight: 300,
          overflow: "auto",
        }}
        placeholder="--Select--"
        allowClear
        treeDefaultExpandAll
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        onChange={onValueChange}
        treeCheckable
        maxTagCount={maxTagCount}
        treeNodeFilterProp="title"
        showCheckedStrategy={showCheckedStrategy}
      >
        <TreeNode value="ALL" title="All" key="0-1">
          {records.map((rec) => (
            <TreeNode
              value={rec[valueField]}
              title={isEmpty(titleFieldCode)?`${rec[titleField]}`:`${rec[titleField]} (${rec[titleFieldCode]})`}
              key={rec.code}
            />
          ))}
        </TreeNode>
      </TreeSelect>
    </div>
  );
}

export default TreeSelectComponent;

TreeSelectComponent.propTypes = {
  selectedValues: PropTypes.array,
  onValueChange: PropTypes.func,
  records: PropTypes.array,
};

TreeSelectComponent.defaultProps = {
  selectedValues: [],
  onValueChange: () => {},
  records: [],
};
