import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { Row, Col, Tag, Button, Text } from "../../common/UIComponents";
import { isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import FilterTag from "./FilterTag";
import { selectedWarehouseNames } from "../../helpers/common";
import { limitString } from "../../helpers/common";
import AppConfig from "../../config/AppConfig";
import EndUserTitle from "../common/EndUserTitle";
import { OrderConfigContext } from "../../context/OrderConfigContext";
import { fetchFilteredUser } from "../../api/UsersApi";

const AppliedFilters = (props) => {
  const {
    filterPlaceHolder,
    handleOnFilterElementChange,
    currentAccount,
    filter,
    currentWarehouse,
    handleOnOrderTokenClose,
    clearFilter,
    handleOnFilterChange,
    accounts,
    warehouseFilter,
    execode,
    isShipper,
    softClearFilter,
    usersData= [],
    // isSearching = false,
    // isPending = false,
  } = props;

  const [ filteredWhs, setFilteredWhs ] = React.useState(selectedWarehouseNames(warehouseFilter));
  const { orderConfig: { orderTypeKeysForFilter = {} } = {} } = useContext(OrderConfigContext);


  React.useEffect(() => {
    setFilteredWhs(selectedWarehouseNames(warehouseFilter));
  }, [ warehouseFilter ]);

  const formatString = (value) => {
    if (!value) return "";
    return value
      .split("_")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getInsertionTypeLabel = (values) => {
    if (Array.isArray(values)) {
      return values.map(value => {
        switch (value) {
          case "API":
            return "EDI";
          case "M":
            return "MANUAL";
          case "S":
            return "SFTP";
          case "PDF":
            return "PDF";  
          case "U":
            return "UPLOADED";
          case "EMAIL":
            return "EMAIL";
          case "Q":
            return "QUOTE";     
          default:
            return "";
        }
      }).join(", ");
    } else {
      return "";
    }
  };

  // Extracted as a SearchResultsRow Component for better readability and reusability
  const SearchResultsRow = ({ search_key_type, search_type, filterPlaceHolder, handleOnOrderTokenClose, softClearFilter }) => {
    const tokens = _.uniq(_.split(filterPlaceHolder.search_key_value, ",")).filter(token => !isEmpty(token));
    const isFullSearch = search_type !== "contains" ;
    const renameKeyData = {
      "customer_order_number" : "ORDER NUMBER",
      "hawb" : "HAWB",
      "mawb" : "MAWB",
      "reference_1" : "REFERENCE 1",
      "reference_2" : "REFERENCE 2",
    }
    const searchLabelText = search_type !== "contains" ? <Text>
      Results for <Text strong>Orders</Text> :&nbsp;
    </Text>
      : <Text>
        Results for <Text strong>{ renameKeyData[ search_key_type ] || search_key_type.toUpperCase() }</Text> which <Text strong>{ search_type }</Text> :&nbsp;
      </Text>;

    return (
      <Row className="marginBottom10">
        <Col xs={ 24 }>
          { searchLabelText }
          {isFullSearch ?
           tokens.map(token => (
            <Tag
              color="blue"
              closable
              onClose={ () => handleOnOrderTokenClose(token) }
              key={ `tags${token}` }
            >
              { _.trim(token, " ") }
            </Tag>
          )) :
          <Tag color="blue">
            { filterPlaceHolder.search_key_value }
          </Tag>
          }
          <Button size="small" type="default" onClick={ softClearFilter }>
            Clear Search
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      { (!isEmpty(filterPlaceHolder.search_key_value)) ?
        (<SearchResultsRow
          search_key_type={!isEmpty(filterPlaceHolder.search_key_type) &&  filterPlaceHolder.search_type === "CONTAINS"  ? filterPlaceHolder.search_key_type.split("_").join(" ") : ""}
          search_type={ filterPlaceHolder.search_type === "CONTAINS" ? "contains" : "EQUALS" }
          filterPlaceHolder={ filterPlaceHolder }
          handleOnOrderTokenClose={ handleOnOrderTokenClose }
          softClearFilter={ softClearFilter }
        />)
        :
        (<Row className="marginBottom10">
          <Col xs={ 24 } className="textBold">
            <span className="marginTop7">Applied filters: &nbsp;</span>
            {/* <Fragment>
              { filter !== "" ? (
                <Tag
                  visible
                  color="blue"
                  closable={ false }
                  onClose={ () => handleOnFilterChange("filter", "") }
                  className="marginTop7"
                >
                  Status: { filter }
                </Tag>
              ) : (
                <Tag visible color="blue">
                  Status: ALL
                </Tag>
              ) }
            </Fragment> */}
            {/* &nbsp;&nbsp; */ }
            { filterPlaceHolder.order_type !== "" && (
              <FilterTag
                InternationalizationText={I18n.t("order.type")}
                onClose={ () => handleOnFilterElementChange("order_type", "") }                
                filterValue={filterPlaceHolder.order_type}
                closable={ filterPlaceHolder.order_type === "" ? false : true}
              >
                {orderTypeKeysForFilter[filterPlaceHolder.order_type]}
              </FilterTag>
            ) }
            <FilterTag
              InternationalizationText={ I18n.t("general.accounts") }
              closable={!isShipper}
              onClose={ () => handleOnFilterElementChange("account_codes", []) }
              filterValue={ filterPlaceHolder?.account_codes }
            >
              {/* {currentAccount.name} */ }
              {
                limitString(filterPlaceHolder?.account_codes &&
                  accounts
                    .filter((acc) =>
                      filterPlaceHolder.account_codes.includes(acc.code)
                    )
                    .map((accName) => accName.name)
                    .join(", "), 5)
              }
            </FilterTag>
            {/* { filteredWhs.length > 0 && (
              <FilterTag
                InternationalizationText={ I18n.t("location.location") }
                // onClose={ () => warehouseFilter.onWarehouseChange("") }
                filterValue={ warehouseFilter.warehouses }
                closable={false} // warehouseFilter.warehouses.length > 1 ? true : false
              >
                { filteredWhs }
              </FilterTag>
            ) } */}

            <FilterTag
              InternationalizationText={ I18n.t("general.zone") }
              onClose={ () => handleOnFilterElementChange("zone_ids", []) }
              closable
              filterValue={ filterPlaceHolder.zone_ids }
            >
              {
                limitString(filterPlaceHolder?.zone_ids &&
                  filterPlaceHolder.zone_ids.map((zone) => zone.label).join(", "), 5)
              }
            </FilterTag>
            { !isEmpty(filterPlaceHolder) &&
              !isEmpty(filterPlaceHolder.fromDate) ? (
              <Fragment>
                <Tag
                  color="blue"
                  closable
                  className="marginTop7"
                  onClose={ () =>
                    handleOnFilterElementChange("dateFilter", {
                      fromDate: null,
                      toDate: null,
                    })
                  }
                >
                  { I18n.t("general.scheduled") } { I18n.t("general.date") }: &nbsp;
                  { moment(filterPlaceHolder.fromDate).format("MMM DD YYYY") ===
                    moment(filterPlaceHolder.toDate).format("MMM DD YYYY") ? (
                    moment(filterPlaceHolder.fromDate).format("MMM DD YYYY")
                  ) : (
                    <Fragment>
                      { moment(filterPlaceHolder.fromDate).format("MMM DD YYYY") }
                      &nbsp;-&nbsp;
                      { moment(filterPlaceHolder.toDate).format("MMM DD YYYY") }
                    </Fragment>
                  ) }
                </Tag>
                &nbsp;&nbsp;
              </Fragment>
            ) : (
              ""
            ) }
            { !isEmpty(filterPlaceHolder.search_key_type) &&
              !isEmpty(filterPlaceHolder.search_key_value) ? (
              <Fragment>
                <Tag
                  color="blue"
                  closable
                  className="marginTop7"
                  onClose={ () =>
                    handleOnFilterElementChange("search_key_type", {
                      search_key_type: "",
                      search_key_value: "",
                    })
                  }
                >
                  { I18n.t(`order.filters.${filterPlaceHolder.search_key_type}`) }:{ " " }
                  { filterPlaceHolder.search_key_value }
                </Tag>
                &nbsp;&nbsp;
              </Fragment>
            ) : (
              ""
            ) }
            { !isEmpty(
              filterPlaceHolder[ I18n.t("order.filters.schedule_orders.type") ]
            ) ? (
              <Fragment>
                { filterPlaceHolder[
                  I18n.t("order.filters.schedule_orders.type")
                ] !== I18n.t("order.filters.schedule_orders.both_key") ? (
                  <Tag
                    color="blue"
                    closable
                    className="marginTop7"
                    onClose={ () =>
                      handleOnFilterElementChange(
                        I18n.t("order.filters.schedule_orders.type"),
                        I18n.t("order.filters.schedule_orders.both_key")
                      )
                    }
                  >
                    { I18n.t("order.filters.schedule_orders.label") }:{ " " }
                    { I18n.t(
                      `order.filters.schedule_orders.${filterPlaceHolder[
                      I18n.t("order.filters.schedule_orders.type")
                      ]
                      }_label`
                    ) }
                  </Tag>
                ) : (
                  <Tag color="blue" visible className="marginTop7">
                    { I18n.t("order.filters.schedule_orders.label") }:{ " " }
                    { I18n.t(
                      `order.filters.schedule_orders.${filterPlaceHolder[
                      I18n.t("order.filters.schedule_orders.type")
                      ]
                      }_label`
                    ) }
                  </Tag>
                ) }
                &nbsp;&nbsp;
              </Fragment>
            ) : (
              ""
            ) }
            { !isEmpty(filterPlaceHolder.vehicle_type) ? (
              <Fragment>
                <Tag
                  color="blue"
                  closable
                  className="marginTop7"
                  onClose={ () =>
                    handleOnFilterElementChange("vehicle_type", "")
                  }
                >
                  { I18n.t("vehicleTypes.title") }:{ " " }
                  { filterPlaceHolder.vehicle_type }
                </Tag>
                &nbsp;&nbsp;
              </Fragment>
            ) : (
              ""
            )}
            { !isEmpty(filterPlaceHolder.undelivered_orders) ? (
              <Fragment>
                <Tag
                  color="blue"
                  closable
                  className="marginTop7"
                  onClose={ () =>
                    handleOnFilterElementChange("undelivered_orders", "")
                  }
                >
                  {"Undelivered Orders"}:{ " " }
                  {formatString(filterPlaceHolder.undelivered_orders)}
                </Tag>
                &nbsp;&nbsp;
              </Fragment>
            ) : (
              ""
            )}
            { filterPlaceHolder?.insertion_type?.length > 0 ? (
            <Fragment>
                <FilterTag
                  InternationalizationText={"Insertion Type"}
                  closable={ filterPlaceHolder.insertion_type.length > 0 }
                  onClose={ () => handleOnFilterElementChange("insertion_type") }
                  filterValue={ getInsertionTypeLabel(filterPlaceHolder?.insertion_type) }
                >
                  { getInsertionTypeLabel(filterPlaceHolder?.insertion_type) }
                </FilterTag>
                &nbsp;&nbsp;
            </Fragment>
          ) : null }
            { filterPlaceHolder?.created_by_ids?.length ? (
            <Fragment>
                <FilterTag
                  InternationalizationText={ I18n.t("general.created_by") }
                  closable={ filterPlaceHolder.created_by_ids === "" ? false : true}
                  onClose={ () => handleOnFilterElementChange("created_by_ids") }
                  filterValue={ filterPlaceHolder.created_by_ids }
                >
                  {limitString(usersData.filter(user => filterPlaceHolder?.created_by_ids.includes(user.user_id)).map(user => user.user_name).join(", "),3)}
                </FilterTag>
                &nbsp;&nbsp;
            </Fragment>
          ) : (
            ""
          )}
            { !isEmpty(filterPlaceHolder.sortBy) &&
              _.isString(
                I18n.t(`order.filters.sortBy.${filterPlaceHolder.sortBy}`)
              ) ? (
              <Fragment>
                {  !["none", "0"].includes(filterPlaceHolder.sortBy) ? (
                  <Tag
                    className="marginTop7"
                    visible
                    color="blue"
                    closable={ filterPlaceHolder.sortBy !== "none" }
                    onClose={ () => handleOnFilterElementChange("sortBy", "none") }
                  >
                    { I18n.t("general.sort_by") }:{ " " }
                    { filterPlaceHolder.sortBy === 'consignee' ? <EndUserTitle/> : I18n.t(`order.filters.sortBy.${filterPlaceHolder.sortBy}`) }
                  </Tag>
                ) : (
                  <Tag color="blue" className="marginTop7" visible>
                    { I18n.t("general.sort_by") }:{ " " }
                    { I18n.t("order.filters.sortBy.none") }
                  </Tag>
                ) }
                &nbsp;&nbsp;
              </Fragment>
            ) : (
              ""
            ) }

            {props.isPending && ( !isEmpty(filterPlaceHolder.selectedSource) ? (
              <Fragment>
                <Tag
                  color="blue"
                  className="marginTop7"
                  closable
                  onClose={() => handleOnFilterElementChange("selectedSource", "")}
                >
                  {I18n.t("general.source")}:{""}
                  {filterPlaceHolder.selectedSource}
                </Tag>
                &nbsp;&nbsp;
              </Fragment>
            ) : (
              <Tag visible color="blue">
                {I18n.t("general.source")}:{"All"}
              </Tag>
            ))}
            <Button size="small" type="default" onClick={ clearFilter }>
              { " " }
              Clear filters
            </Button>
          </Col>
        </Row>)
      }
    </Fragment>
  );
};

AppliedFilters.propTypes = {
  filter: PropTypes.string.isRequired,
  filterPlaceHolder: PropTypes.shape().isRequired,
  handleOnFilterElementChange: PropTypes.func.isRequired,
  currentWarehouse: PropTypes.shape().isRequired,
  currentAccount: PropTypes.shape().isRequired,
  handleOnOrderTokenClose: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  handleOnFilterChange: PropTypes.func.isRequired,
};

export default AppliedFilters;
