/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useEffect, useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import _, { result } from 'lodash';
import {
  Col,
  Row,
  Icon,
  Button,
  Card,
  Divider,
  Select,
  Modal,
  Form,
} from "../../common/UIComponents";
import { alertMessage, checkNegitive, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import Profile from "../common/Profile";
import InvoiceContact from "./InvoiceContact";
import AppConfig from "../../config/AppConfig";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import AddressInfo from "../common/AddressInfo";
import BranchesIcon from "../common/BranchesIcon";
import EditIcon from "../common/EditIcon";
import BaseModal from "../BaseModal";
import { fetchUsers } from "../../api/UsersApi";
import {
  UpdateDriver,
  fetchBillingDetails,
  fetchInvoices,
} from "../../api/Billing";
import { WarehouseContext } from "../../context/WarehouseContext";
import EndUserTitle from "../common/EndUserTitle";
import { importDeclaration } from "@babel/types";

const BillingDrivers = ({
    invoice,
    currentObj,
    isReadyOnly,
    levelOfServices,
    handleOnChange,
    currentLos,
    reGenerate,
    showAddOrdersModal,
    showAddOrders,
    isLocked,
    showActions,
    getInvoices,
    showEmailModal,
    isSendingMail,
    driversList = [],
  updateDriverDetails
}) => {
  // State initialization
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentDriverId, setCurrentDriverId] = useState('');
  const [currentLocInfo, setCurrentLocInfo] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [driversInfo, setDriversInfo] = useState([]);
  const warehouseFilter = useContext(WarehouseContext);
  const [warehouses, setWarehouses] = useState([]);

  // Initialize current order and drivers when props change
  useEffect(() => {
    if (currentObj) {
      setCurrentOrder(currentObj);
      // Initialize driversInfo from currentObj
      const initialDriversInfo = currentObj?.driver_details || [];
      setDriversInfo(_.sortBy(initialDriversInfo, "loc_order_sequence"));
    }
  }, [currentObj]);

  useEffect(() => {
    if (driversList) {
      setDrivers(driversList);
    }
  }, [driversList]);

  useEffect(() => {
    setWarehouses(warehouseFilter.warehouses);
  }, [warehouseFilter.selectedWarehouses, warehouseFilter.warehouses]);

  const currentWh =
    typeof warehouseFilter.selectedWarehouses === "string"
      ? _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses,
        })
      : _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses?.[0],
        });

  const handleDriverChnage = (value) => {
    setSelectedDriver(value);
  };

  const handleEdit = (loc) => {
    setCurrentDriverId(loc.driver_id)
    setCurrentLocInfo(loc)
    setShowEditModal(true);
  };

  const resetModalState = () => {
    setShowEditModal(false);
    setCurrentDriverId('');
    setCurrentLocInfo(null);
    setSelectedDriver("");
  };

  const handleSubmit = async () => {
    if (!selectedDriver) {
      alertMessage("Please select a driver", "error");
      return;
    }

    setInProgress(true);
    try {
    const orderIds = [currentOrder.customer_order_id];
    const locationId = currentLocInfo?.location_id || '' //currentOrder.driver_details[0]?.location_id ||  "";
    const payload = {
      order_ids: orderIds,
      old_driver_id: currentDriverId,
      new_driver_id: selectedDriver,
      warehouse_id: currentWh ? currentWh.id : "",
      location_id: locationId
    };
      const result = await 
      UpdateDriver(payload);
      
        if (result.success) {
        const billingDetails = await fetchBillingDetails(currentOrder.customer_order_id);
        
        if (billingDetails.success) {
          const orderDriverDetails = billingDetails.invoice.order_driver_details || [];
          const orderInfo = orderDriverDetails.find(
            order => order.customer_order_id === currentOrder.customer_order_id
          );
          
          const updatedDriversInfo = _.sortBy(orderInfo?.driver_details || [], "loc_order_sequence");
          
          setDriversInfo(updatedDriversInfo);
          updateDriverDetails(orderDriverDetails);
          
          alertMessage("Driver Updated Successfully", "success", 10);
          resetModalState();
        }
      }
    } catch (error) {
      alertMessage("Failed to update driver", "error");
      console.error("Driver update failed:", error);
    } finally {
      setInProgress(false);
    }
  };

  const renderEditModal = () => {
    return (
      <Modal
        title={"Change Driver"}
        onOk={handleSubmit}
        onCancel={resetModalState}
        bodyStyle={{ padding: 10 }}
        visible={showEditModal}
        okButtonProps={{
            loading: inProgress
        }}
      >
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form>
                <span className="textBold">{I18n.t("general.Driver")}:</span>
                <Select
                  showSearch
                  value={selectedDriver ? selectedDriver : ""}
                  onChange={handleDriverChnage}
                  filterOption={(input, option) => {
                    if (option.props.children) {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                  }}
                  style={{ width: "80%", height: "20%", marginLeft: 10 }}
                >
                  <Select.Option key={"driver"} value={""}>
                    --select--
                  </Select.Option>
                  {drivers
                    .filter((driver) => driver.id !== currentDriverId)
                    .map((driver) => (
                      <Select.Option key={driver.id} value={driver.id}>
                        {driver.full_name} ({driver.employee_code})
                      </Select.Option>
                    ))}
                </Select>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };

  return (
    invoice && (
      <Fragment>
        {renderEditModal()}

        <Row>
          {!isEmpty(driversInfo) && driversInfo?.length && (
            <Col
              md={24}
              className="paddingLeft5 "
            >
              <h4 className="main_heading textBold">Driver Details</h4>
              <Row>
                {driversInfo.map((rec) => (
                  <Col
                    key={`${rec.driver_id}-${rec.location_id}`}
                    md={24 / driversInfo.length}
                    className=""
                  >
                    {rec.type_of_location && (
                      <div className="main_heading textBold fontSize13">
                        {rec.type_of_location}
                      </div>
                    )}
                    <div className="main_heading textBold fontSize13">
                      {rec.driver_name}
                      &nbsp;&nbsp;
                      {!["LH"].includes(rec.type_of_order) &&
                        invoice.status ===
                          I18n.t("invoices.ready_for_billing") &&
                        showActions === true && (
                          <EditIcon handleClick={() => handleEdit(rec)} />
                        )}
                    </div>
                    <div className="main_heading textBold fontSize13">
                      <Icon type="number" />
                      &nbsp;
                      {rec.driver_code}
                    </div>
                    <div className="fontSize12">
                        <InvoiceContact
                        // key={`${rec.driver_id}-${JSON.stringify(rec)}`}
                        orderNo={currentOrder.customer_order_id}
                        data={rec}
                        showIcon
                        updateParentComponent={updateDriverDetails}
                        driverNo={rec.driver_number}
                        hideRates={true}
                        />
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          )}
        </Row>
      </Fragment>
    )
  );
};

BillingDrivers.propTypes = {
  invoice: PropTypes.shape().isRequired,
  currentObj: PropTypes.shape(),
  isReadyOnly: PropTypes.bool.isRequired,
  showActions: PropTypes.bool,
  driversList: PropTypes.arrayOf(PropTypes.shape()),
  updateDriverDetails: PropTypes.func.isRequired,
  levelOfServices: PropTypes.arrayOf(PropTypes.shape()),
  handleOnChange: PropTypes.func.isRequired,
  showAddOrdersModal: PropTypes.bool.isRequired,
};

BillingDrivers.defaultProps = {
  currentObj: {},
  isReadyOnly: false,
  showActions: false,
  driversList: [],
  levelOfServices: [],
  showAddOrdersModal: false,
};

export default BillingDrivers;