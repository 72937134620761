/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react'
import _ from 'lodash'
import { capacityValidationForRoute } from '../api/PreplanApi'
import { isEmpty } from '../common/Common'
import I18n from '../common/I18n'
import { Icon , Modal, notification, Typography, Row, Col} from '../common/UIComponents'
import { formatSurfaceArea, formatWeight } from './orders'
import Orders from '../containers/Orders'
import CapacityOrders from '../components/orders/CapacityOrders'
import UnassignedErrors from '../components/preplan/UnassignedErrors'

export const findOverloadReasons = (record) => {
  const reasons = []
  if (record.total_wt_overload || record.max_wt_overload) {
    reasons.push(I18n.t('order.weight'))
  }
  if (record.total_vol_overload || record.max_dim_overload) {
    reasons.push(I18n.t('order.surface_area'))
  }
  return reasons.join(' & ')
}

export const findOverloadOrdersByZone = (zones) => {
  let overloadedOrders = [];
  zones.forEach(record => {
    const isOverloadedByWeight = record.total_wt_overload || record.max_wt_overload;
    const isOverloadedByVol = record.total_vol_overload || record.max_dim_overload;
    
    if(isOverloadedByWeight){
      const max_wt_orders = record.max_wt_orders.map(order => order.customer_order_number)
      overloadedOrders = [].concat(overloadedOrders, max_wt_orders)
    }
    if(isOverloadedByVol){
      const max_dim_orders = record.max_dim_orders.map(order => order.customer_order_number)
      overloadedOrders = [].concat(overloadedOrders, max_dim_orders)
    }
  })
  return overloadedOrders;
}

export const findOverloadReasonsWithDetails = (record) => {
  const isOverloadedByWeight = record.total_wt_overload || record.max_wt_overload;
  const isOverloadedByVol = record.total_vol_overload || record.max_dim_overload;
  let overloadedOrders = [];
  if(isOverloadedByWeight){
    const max_wt_orders = record.max_wt_orders.map(order => order.customer_order_number)
    overloadedOrders = [].concat(overloadedOrders, max_wt_orders)
  }
  if(isOverloadedByVol){
    const max_dim_orders = record.max_dim_orders.map(order => order.customer_order_number)
    overloadedOrders = [].concat(overloadedOrders, max_dim_orders)
  }
  const totalOrders = _.uniqBy(_.flattenDeep(_.compact([].concat(record.max_wt_orders, record.max_dim_orders))), 'id');
  return (<Fragment>
  <Row className='marginBottom10'>
    {(isOverloadedByWeight) && <Col xs={12}>
    <div> Order(s) {I18n.t('order.weight')}: <span className='textBold'>{formatWeight(record.orders_tot_wt)}</span></div>
    <div> {I18n.t("preplan.capacity.veh_max_accumable_weight_sf")}: <span className='textBold'>{formatWeight(record.trucks_tot_wt)}</span></div>
        {/* <br/><span className='textBold'>Overloaded orders: {record.max_wt_orders.join(',')}</span> */}
      </Col>
    }
    {isOverloadedByVol && <Col xs={12}>
    <div> Order(s) {I18n.t('order.surface_area')}: <span className='textBold'>{formatSurfaceArea(record.orders_tot_vol)}</span></div>
    <div> {I18n.t("preplan.capacity.veh_max_accumable_surface_sf")}: <span className='textBold'>{formatSurfaceArea(record.trucks_tot_wt)}</span></div>
      {/* <br/><span className='textBold'>Overloaded orders: {record.max_dim_orders.join(',')}</span> */}
    </Col>
    }
  </Row>
  {(isOverloadedByWeight || isOverloadedByVol) && overloadedOrders.length > 0 &&
    <div className='marginTop10'>
      <div className='textBold textRed'>{I18n.t('preplan.capacity.overloaded_orders')}:</div>
      {/* <Typography.Paragraph ellipsis={{ rows: 1, expandable: true }}>{_.uniq(_.flattenDeep(_.compact(overloadedOrders))).join(',')}</Typography.Paragraph> */}
      <div style={{ maxWidth: 400}}>
      <CapacityOrders
        data={totalOrders}
        pagination={{ position: 'none'}}
        scroll={{ y: 100 }}
        size={"small"}
      />
      </div>
    </div>
  }
  </Fragment>)
}

export const displayUnassignedErrors = (orders) => {
  return (<Fragment>
  {orders.length > 0 &&
    <div className='marginTop10'>
      <div style={{ maxWidth: 600}}>
        <UnassignedErrors
          data={orders}
          tableLayout="fixed"
          pagination={{ position: 'none'}}
          style={{
            width: 600,
            overflow: "hidden",
          }}
          scroll={{ y: 200 }}
          bordered
          size={"small"}
        />
      </div>
    </div>
  }
  </Fragment>)
}

 export const showRouteValidationError = (capacity_info, routeName, cb, setStopProgress) =>{
    const capacityInfo = capacity_info || {};
    const overloadedBy = findOverloadReasons(capacityInfo);
    Modal.confirm({
      title: "Errors",
      content: (
        <Fragment>
          <h4 >Route: {routeName} : <span className="textRed">{I18n.t('preplan.capacity.capacity_overloaded_by')} {overloadedBy}</span></h4>
          {findOverloadReasonsWithDetails(capacity_info)}
          <div className="marginTop10 textBold">{I18n.t('messages.still_proceed')}</div>
        </Fragment>
      ),
      width: "40%",
      onOk: () => {
        if(cb) {
          cb();
        }
      },
      okText: "Yes",
      cancelText: "No",
      onCancel: () => {
        setStopProgress(false);
      }
    });
  }

  export const routeConfirmation = (driver, cb, setStopProgress) =>{
    Modal.confirm({
      title: "Errors",
      content: (
        <Fragment>
          <h4 > Route already exists for driver: {driver} </h4>
          <h4> Do you want to terminate the existing route? </h4>
          <div className="marginTop10 textBold">{I18n.t('messages.still_proceed')}</div>
        </Fragment>
      ),
      width: "40%",
      onOk: () => {
        if(cb) {
          cb(true);
        }
      },
      okText: "Yes",
      cancelText: "No",
      onCancel: () => {
        setStopProgress(false);
      }
    });
  }

export const doRouteCapacityValidation = (
  doCapacityValidation,
  preplanId,
  allocType,
  data,
  cb,
  setStopProgress,
) => {
  if (doCapacityValidation) {
    // setRouteProgress(true)
    // showFlashMessage('info', I18n.t('messages.preplan_process'));
    notification.open({
      key: preplanId,
      message: I18n.t('preplan.capacity.validation'),
      description: <div>{I18n.t('messages.capacity_process')}</div>,
      icon: <Icon type="loading" style={{ color: '#108ee9' }} />,
      placement: 'bottomRight',
      duration: 20,
    })
    const payload = {
      driver_id:
        allocType === 'assignToUser'
          ? data.primary_driver_id
          : data.primary_driver_id,
      // order_ids:
      //   allocType === 'moveOrder' ? [...data.order_id] : [...data.order_ids],
      order_ids: [...data.order_ids_for_validation],
    }
    const routeName =
      ['allocateToRoute', 'assignToUser', "add_driver"].includes(allocType) ? data.routeName : data.newRouteName
    capacityValidationForRoute(payload, preplanId).then((result) => {
      if (result.success) {
        notification.close(preplanId)
        if (!isEmpty(result.preplan) && !result.preplan.capacity_overloaded) {
          if (cb) {
            cb()
          }
        } else {
          showRouteValidationError(
            result.preplan.capacity_info,
            routeName,
            cb,
            setStopProgress,
          )
        }
      } else {
        setStopProgress(false)
        notification.error({
          key: preplanId,
          message: routeName,
          description: <div>{result.errors[0]}</div>,
          placement: 'bottomRight',
        })
      }
    })
  } else {
    if (cb) {
      cb()
    }
  }
}